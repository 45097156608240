import React, { useEffect, useState } from 'react'
import cashierViewQueue from '../../services/Cashier/view';
import { useAuth } from '../../context/AuthContext';
import AcceptPaymentCashier from '../Cashier/Components/PaymentAdmin';
import cashierCreateQueue from '../../services/Cashier/create';

function CashierQuery() {
    
    const [transactions, setTransactions] = useState([]);
    const [filteredTransaction, setFilteredTransaction] = useState(transactions);
    const [acceptPaymentModalStatus, setAcceptPaymentModalStatus] = useState(false);
    const [selectedQuery, setSelectedQuery] = useState([])

    const { authorizationHeader, data } = useAuth();

    const cashierView = new cashierViewQueue();
    const cashierCreate = new cashierCreateQueue();

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    
    const totalPages = Math.ceil(filteredTransaction.length / rowsPerPage);

    const getStudentQueryListCashierTodayFunc = async () => {
        if (data?.user?.IdentificationUID) {
            const rescashier = await cashierView.getStudentQueryListCashierToday(authorizationHeader, { Role: data.user.Role });
            setTransactions(rescashier.rows);
            setFilteredTransaction(rescashier.rows);
        };
    };

    const searchStudent = (e) => {
        const { value } = e.target;
        setFilteredTransaction(
            String(value).length > 0 
            ? transactions.filter((item) => item.fullname.toLowerCase().includes(value.toLowerCase()))
            : transactions
        );
          
    }

    const selectQuery = (e) => {
        try {
            if (data?.user?.IdentificationUID) {
                cashierCreate.createQueueSelectStudent(authorizationHeader, { QueueStudentNo: e.queuestudentno, UserUID: data?.user?.IdentificationUID }).then((res) => {
                    setAcceptPaymentModalStatus(true);
                    setSelectedQuery(e);
                }).catch((err) => {
                    alert(err.message);
                })
            }
            
        } catch(err) {
            console.log(err)
        }
    }
    
    const currentTransactions = filteredTransaction.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            getStudentQueryListCashierTodayFunc()
        }, 1000);
        
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            { acceptPaymentModalStatus && <AcceptPaymentCashier modalStatus={acceptPaymentModalStatus} setModalStatus={setAcceptPaymentModalStatus} data={selectedQuery} /> }
            <div className="container mx-auto my-8 shadow-lg p-5">
                <div className='container text-end'>
                    <input onChange={searchStudent} type="search" name="Student" placeholder='Search Student' className="w-1/4 p-3 mb-4" />
                </div>
                <table className="min-w-full table-auto">
                    <thead className='text-xl'>
                        <tr className="bg-gray-200 text-gray-700 uppercase text-lg leading-normal">
                            <th className="py-3 px-6 text-left">No.</th>
                            <th className="py-3 px-6 text-left">Date</th>
                            <th className="py-3 px-6 text-left">Queue No.</th>
                            <th className="py-3 px-6 text-left">Name</th>
                            <th className="py-3 px-6 text-left">Section</th>
                            <th className="py-3 px-6 text-left">Type of Transaction</th>
                            <th className="py-3 px-6 text-left"></th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-lg font-light text-lg">
                        {currentTransactions.map((transaction, index) => (
                            <tr key={index + 1} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-left">{index + 1}</td>
                                <td className="py-3 px-6 text-left">{new Date(transaction.date).toLocaleString('en-US', { hour12: true })}</td>
                                <td className="py-3 px-6 text-left">{transaction.queueno}</td>
                                <td className="py-3 px-6 text-left">{transaction.fullname}</td>
                                <td className="py-3 px-6 text-left">{transaction.section}</td>
                                <td className="py-3 px-6 text-left">{transaction.transactiontype}</td>
                                <td className="py-3 px-6 text-left">
                                    <button onClick={() => selectQuery(transaction)} className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600">
                                        Proceed
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* Pagination Controls */}
                <div className="flex justify-between items-center mt-4">
                    <button
                        className={`bg-blue-500 text-white px-4 py-2 rounded ${
                            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span> Page {currentPage} of {totalPages} </span>
                    <button
                        className={`bg-blue-500 text-white px-4 py-2 rounded ${
                            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    );
}

export default CashierQuery
import React from 'react'
import Modal from '../../../../../components/Modal'
import './index.css'

function PermitModalNotification(props) {

    const { modalStatus, setModalStatus, data } = props;

    return (
        <>
            <Modal onShow={modalStatus} onClose={setModalStatus} size="large" className={'rounded'}>
                <Modal.Header onClose={setModalStatus} className="bg-darkblue">
                    <h5 className="text-white text-lg text-center">Successfully Submitted!</h5>
                </Modal.Header>

                <Modal.Body className="bg-green flex flex-col items-center justify-center text-white min-h-48">
                    <div className="text-6xl font-bold">{data}</div>
                    <div className="mt-2 text-sm">Your Queue No for Registrar</div>
                    <div className='mt-3 w-full text-end'>
                        <button className='bg-teal-500 p-4' onClick={() => window.location.reload()}>Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PermitModalNotification;
import httpService from "../../http.service";

class studentCreateQueue {

    createPaymentTransaction = async (authorizationHeader, data) => {
        const config = {
            headers: {
              authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return await  httpService.post('student/createPaymentTransaction', data, config);

    }

    createPermitTransaction = async (authorizationHeader, data) => {
        const config = {
            headers: {
              authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return await  httpService.post('student/createPermitTransaction', data, config);

    }

}

export default studentCreateQueue;
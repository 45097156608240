import Axios from "axios";

const API_URL = process.env.REACT_APP_API_DOMAIN;

// Set default Axios base URL
Axios.defaults.baseURL = API_URL;

export class HttpService {
  constructor() {
    this._axios = Axios.create({
      baseURL: API_URL, // Set base URL
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json',
      },
      withCredentials: true,
    });
  }

  // Add request interceptors
  addRequestInterceptor(onFulfilled, onRejected) {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  }

  // Add response interceptors
  addResponseInterceptor(onFulfilled, onRejected) {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  }

  // GET request
  async get(url, config = {}) {
    try {
      const response = await this._axios.get(url, config);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  // GET Params request
  async getSingle(url, params = {}, config = {}) {
    try {
      const response = await this._axios.get(url, {
        ...config,
        params: params, 
      });
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  }



  // POST request
  async post(url, data, config = {}) {

    try {
      const response = await this._axios.post(url, data, config);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  // PUT request
  async put(url, data, config = {}) {
    try {
      const response = await this._axios.put(url, data, config);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  // PATCH request
  async patch(url, data, config = {}) {
    try {
      const response = await this._axios.patch(url, data, config);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  // DELETE request
  async delete(url, params = {}, config = {}) {
    try {
      const response = await this._axios.delete(url, {
        params: params,
        config
      });
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Handle errors and extract message
  handleError(error) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
}

export default new HttpService();

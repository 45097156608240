import { createContext, useState, useEffect, useContext } from "react";
import CookieManager from '../utils/Cookies/CookieManager';
import {jwtDecode} from "jwt-decode"; // Ensure correct import

const AuthContext = createContext({
  isAuthenticated: false,
  data: null,
  authorizationHeader:  null,
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const cookie = new CookieManager();
  const cookieName = '_Kyūkukkī';
  const token = cookie.getSecureCookie(cookieName);

  const [isAuthenticated, setIsAuthenticated] = useState(!!token); 
  const [data, setData] = useState(token ? jwtDecode(token) : null); 
  const [authorizationHeader, setAuthorizationHeader] = useState(token); 

  useEffect(() => {
    if (!token) {
      setIsAuthenticated(false);
      setData(null);
      return;
    }
    try {
      const decodedToken = jwtDecode(token);
      setIsAuthenticated(true);
      setData(decodedToken);
    } catch (error) {
      console.error("Error decoding token:", error);
      setIsAuthenticated(false);
      setData(null);
    }
  }, [token]); 

  const login = (userData) => {
    cookie.setSecureCookie(cookieName, userData, 12); 
    const newToken = cookie.getSecureCookie(cookieName);
    setAuthorizationHeader(newToken)
    setIsAuthenticated(true);
    setData(jwtDecode(newToken));
  };

  const logout = () => {
    cookie.removeSecureCookie(cookieName);
    setIsAuthenticated(false);
    setData(null);
    alert('Successfully Logged Out!');
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, data, authorizationHeader }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { useContext, useState } from "react";
import IMMSLogo from "../../assets/Logo.png";
import Modal from "../../components/Modal";
import Authentication from '../../services/Auth'
import { useAuth } from "../../context/AuthContext";

function Login(props) {

  const { login } = useAuth();

  const { modalStatus, setModalStatus } = props;
  const [data, setData] = useState({ Role: 2 });

  const { authorizationHeader } = useAuth();

  const inputData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleCloseModal = () => {
    setModalStatus(false);
  };

  const handleLoginButton = async (e) => {
    e.preventDefault(); 
    const config = {
      headers: {
        Authorization: authorizationHeader
      },
      params: data
    };
    try {
      const auth = await Authentication.userAuthentication(data, config);
      alert(auth.message);
      login(auth.token);
      window.location.href = '/';
    } catch(err) {
      alert(err.message)
    }
  };

  return (
    <Modal
      onShow={modalStatus}
      onClose={setModalStatus}
      size="xxl" 
    >
      <Modal.Header
        title="ILOILO MERCHANT MARINE SCHOOL QUEUING SYSTEM"
        onClose={setModalStatus}
        className="text-white text-center"
        style={{ backgroundColor: "#143E69" }}
      />

      <Modal.Body>
        <div className="flex flex-row min-h-96">
          {/* Left: Logo Section */}
          <div className="w-1/2 p-5 flex flex-col justify-center items-center">
            <img src={IMMSLogo} alt="login-image" className="w-1/2 h-1/2" />
          </div>

          {/* Right: Form Section */}
          <div className="w-1/2 p-5 mt-4 ">
            <form className="max-w-sm mx-auto mt-4 " onSubmit={handleLoginButton}>
              <div className="mb-5 ">
                <select
                  onChange={inputData}
                  name="Role"
                  className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                  required
                  defaultValue={2}
                >
                  <option value="">-- Select Role --</option>
                  <option value="1">Staff</option>
                  <option value="2">Student</option>
                  <option value="0">Viewer</option>
                </select>
              </div>
              {
                Number(data.Role) === 1 ?
                <>
                  <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                      EMAIL
                    </label>
                    <input
                      onChange={inputData}
                      type="email"
                      name="email"
                      className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                      placeholder="registrar2025@imms.education"
                      required
                    />
                  </div>
                  <div className="mb-5">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                      PASSWORD
                    </label>
                    <input
                      onChange={inputData}
                      type="password"
                      id="password"
                      name="password"
                      className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                      placeholder="password"
                      required
                    />
                  </div>
                </>
                :
                <>
                  <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                      STUDENT ID
                    </label>
                    <input
                      onChange={inputData}
                      type="text"
                      name="email"
                      className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                      placeholder="registrar2025@imms.education"
                      required
                    />
                  </div>
                </>
              }
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center"
              >
                Login
              </button>
              <hr />
              <button
                type="button"
                onClick={handleCloseModal}
                className="w-full text-center mt-4 underline"
              >
                Close
              </button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Login;

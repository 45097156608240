import React, {  useState, useContext } from 'react';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import './index.css';
import Logo from '../../assets/Logo.png'
import Login from '../Auth'
import { useAuth } from '../../context/AuthContext';
import CookieManager from '../../utils/Cookies/CookieManager';


const Layout = () => {
    const [loginModalStatus, setLoginModalStatus] = useState(false)

    const { data, isAuthenticated, logout } = useAuth();

    const cookie = new CookieManager();

    const handleLogin = () => {
      setLoginModalStatus(true)
    };
    
    const handleLogout = () => {
      logout();
    };

    return (
      <>
        { loginModalStatus && <Login modalStatus={loginModalStatus} setModalStatus={setLoginModalStatus} /> }
        <div className="side-panel-layout">
          {/* Header for system logo/name */}
          <header className="system-header">
            <h1 className='text-2xl font-semibold'>ILOILO MERCHANT MARINE SCHOOL QUERY SYSTEM</h1>
          </header>

          {/* Container for side panel and main content */}
          <div className="content-container">
            {/* Left side panel */}
            <aside className="side-panel text-xl ">
              <nav>
                <ul>
                  <li >
                    <div className='w-full flex justify-center items-center '>
                      <img
                      src={Logo}
                      style={{ width: '180px' }}
                      />
                    </div>
                  </li>
                  <li className='text-center mb-5 mt-5'>
                  <Link style={{ color: 'white', textDecoration: 'underline' }}>
                    {data?.user?.fullname} <br /> {data?.user?.Role === "STF_CAS" ? 'CASHIER' : data?.user?.Role === "REG" ? 'REGISTRAR' : data?.user?.Role }
                  </Link>
                  </li>
                  <li>
                    <div style={{ padding: '15px' }}/>
                  </li>
                  <li>
                    <NavLink to="/" className="full-width-link">
                      Dashboard
                    </NavLink>
                  </li>
                  {
                    data?.user?.Role === 'STF_CAS' ?
                      <>
                        <li>
                          <NavLink to="/cashier-query" className="full-width-link">
                            Cashier Query
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/view" className="full-width-link">
                            Viewer
                          </NavLink>
                        </li>
                      </>
                    :
                    data?.user?.Role === 'REG' ?
                      <>
                        <li>
                          <NavLink to="/registrar-query" className="full-width-link">
                            Registrar Query
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/view" className="full-width-link">
                            Viewer
                          </NavLink>
                        </li>
                      </>
                    :
                    data?.user?.Role === 'BSMT' || data?.user?.Role === 'BSMarE' ?
                    <>
                      <li>
                        <NavLink to="/cashier-student-transaction" className="full-width-link">
                          Cashier
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/registrar-student-transaction" className="full-width-link">
                          Registrar
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/student-queries" className="full-width-link">
                          List of Queries
                        </NavLink>
                      </li>
                    </>
                    :
                    null
                  }
                </ul>
              </nav>

              <div className="auth-buttons">
                { !isAuthenticated ? (
                  <button onClick={handleLogin} className="auth-button">
                    Login
                  </button>
                ) : (
                  <button onClick={handleLogout} className="auth-button">
                    Logout
                  </button>
                )}
              </div>
            </aside>

            {/* Main content */}
            <main className="main-content flex justify-center items-center ">
              <Outlet />
            </main>
          </div>
        </div>
      </>
    );
};

export default Layout;

import React, { useContext, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';

function Dashboard() {

    const { checkLogin, data } = useAuth();

    return (
        <>
            <div className="container p-4 text-center flex justify-center items-center text-xl" style={{ height: '87vh' }}>

            </div>
        </>
    );
}

export default Dashboard;

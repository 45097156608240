const ones = [
    "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", 
    "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
];

const tens = [
    "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
];

const scales = ["", "Thousand", "Million", "Billion", "Trillion"];

function NumberToWord(num) {
    if (num === 0) return "Zero";

    let word = '';

    for (let i = 0; num > 0; i++) {
        let chunk = num % 1000;
        if (chunk) {
            let chunkInWords = convertChunkToWords(chunk);
            word = chunkInWords + (scales[i] ? " " + scales[i] : "") + " " + word;
        }
        num = Math.floor(num / 1000);
    }

    return word.trim();
}

function convertChunkToWords(num) {
    let word = '';

    if (num > 99) {
        word += ones[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
    }

    if (num > 19) {
        word += tens[Math.floor(num / 10)] + " ";
        num %= 10;
    }

    word += ones[num];

    return word.trim();
}

export default NumberToWord;

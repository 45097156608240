import httpService from "../../http.service";

class cashierUpdateQueue {

    updateQueueSelectStudent = (authorizationHeader, data) => {
        const config = {
            headers: {
                authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        }
        return httpService.put('cashier/updateCancelQueueSelectStudent', data, config);
    }

    updateQueueSelectStudentSuccessful = (authorizationHeader, data) => {
        const config = {
            headers: {
                authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        }
        return httpService.put('cashier/updateQueueSelectStudentSuccessful', data, config);
    }

}

export default cashierUpdateQueue;
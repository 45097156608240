import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import Other from '../../../services/Other';
import studentCreateQueue from '../../../services/Student/create';
import PaymentModalNotification from '../Modals/Payments';
import cashierViewQueue from '../../../services/Cashier/view';

function Payments() {


    const { data } = useAuth();
    const [studentData, setStudentData] = useState({ StudentID: data.user.Identification });
    const [bankList, setBankList] = useState([]);
    const [modalPaymentNotificationStatus, setModalPaymentNotificationStatus] = useState(false);
    const [queueno, setQueueNo] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [totalPayable, setTotalPayable] = useState(0);

    const { authorizationHeader } = useAuth();
    const other = new Other();
    const student = new studentCreateQueue();
    const rescashier = new cashierViewQueue();
    
    const inputData = (e) => {
        setStudentData({
            ...studentData,
            [e.target.name]: e.target.value
        })
    };

    const getBankListFunc = () => {
        try {
            other.getBankList(authorizationHeader).then((response) => {
                setBankList(response.rows);
                return rescashier.getTotalPayableStudentCopy(authorizationHeader, { StudentID:  data.user.Identification })
            }).then((res) => {
                if (Number(res.rows[0].TotalPayable) === 0) {
                    alert('Congrats You are  all paid up');
                    return;
                } else {
                    setTotalPayable(Number(res.rows[0].TotalPayable))
                }
            }).catch((err) => {
                alert(err.message)
            });
        } catch (err) {
            console.error(err.message)
        }
    }

    const handleSubmitData = async (e) => {
        e.preventDefault();
        if (studentData.TransactionType === 'Tuition') {
            if (Number(totalPayable) >= Number(studentData.Amount)) {
                setButtonClicked(true);
                try {
                    const respayment = await student.createPaymentTransaction(authorizationHeader, studentData);
                    console.log(respayment)
                    if (respayment.res[0][0].QueueNo) {
                        setQueueNo(respayment.res[0][0].QueueNo);
                        setModalPaymentNotificationStatus(true);
                    } else {
                        alert('Unsuccessful Submit Payment!');
                        setButtonClicked(false);
                    }
                } catch (err) {
                    console.log(err.message);
                    setButtonClicked(false);
                }
            } else {
                alert('Please Check your payment!');
            }
        } else {
            setButtonClicked(true);
            try {
                const respayment = await student.createPaymentTransaction(authorizationHeader, studentData);
                if (respayment.res[0][0].QueueNo) {
                    setQueueNo(respayment.res[0][0].QueueNo);
                    setModalPaymentNotificationStatus(true);
                }  else {
                    alert('Unsuccessful Submit Payment!');
                }

            } catch (err) {
                console.log(err.message);
                setButtonClicked(false);
            }
        }
    }

    useEffect(() => {
        getBankListFunc();
    }, []);

    return (
        <>
            { modalPaymentNotificationStatus && <PaymentModalNotification modalStatus={modalPaymentNotificationStatus} setModalStatus={setModalPaymentNotificationStatus} data={queueno} /> }
            <div className="max-w rounded overflow-hidden shadow-lg m-5 bg-white">
                <h5 className='font-bold text-xl mb-2 text-center p-3'>PAYMENTS</h5>
                <div className="px-6 py-4">
                    <form onSubmit={handleSubmitData}>
                        <div className="grid grid-cols-12 gap-4 mt-2">
                            <div className='col-span-3'></div>
                            <div className='col-span-3'></div>
                            <div className='col-span-3'></div>
                            {
                                studentData.TransactionType === 'Tuition' ?
                                <>
                                    <div className='col-span-3'>
                                        <h6 className="text-xl font-medium text-start">Total Remaining Balance</h6>
                                        <input value={totalPayable} className="w-full p-2 border rounded-md text-xl" readOnly />
                                    </div>
                                </>
                                :
                                <>
                                    <div className='col-span-3'></div>
                                </>
                            }
                            <div className="col-span-3">
                                <h6 className="text-xl font-medium">1. Payment Mode</h6>
                                    <select onChange={inputData} name="PaymentMode" className="w-full p-2 border rounded-md text-xl" required >
                                        <option value="">Please Select Item...</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Cheque">Cheque</option>
                                    </select>
                            </div>

                            <div className="col-span-3">
                                <h6 className="text-xl font-medium">2. Payment Type</h6>
                                <select onChange={inputData} name="TransactionType" className="w-full p-2 border rounded-md text-xl" required >
                                    <option value="">Please Select Item...</option>
                                    <option value="Tuition">Tuition Payment</option>
                                    <option value="Other">Other Payment</option>
                                </select>
                            </div>
                            
                            <div className="col-span-3">
                                <h6 className="text-xl font-medium">3. Amount</h6>
                                <input type="text" onChange={inputData} name="Amount" className="w-full p-2 border rounded-md text-xl" required  />
                            </div>
                            
                            <div className="col-span-3">
                                <h6 className="text-xl font-medium">4. Date Payment</h6>
                                <input onChange={inputData} name="PaymentDate" className="w-full p-2 border rounded-md text-xl" type="datetime-local" required  />
                            </div>
                            
                            <div className="col-span-3">
                                <h6 className="text-xl font-medium">5. Cheque Date</h6>
                                <input onChange={inputData} name="ChequeDate" className="w-full p-2 border rounded-md text-xl" type="datetime-local" />
                            </div>
                            
                            <div className="col-span-3">
                                <h6 className="text-xl font-medium">6. Cheque No</h6>
                                <input onChange={inputData} name="ChequeNo" className="w-full p-2 border rounded-md text-xl" type="text" />
                            </div>
                            
                            <div className="col-span-6">
                                <h6 className="text-xl font-medium">7. Bank</h6>
                                <select onChange={inputData} className="w-full p-2 border rounded-md text-xl" name="BankNo" >
                                    <option></option>
                                    {
                                        bankList && bankList.map((item, index) => (
                                            <option key={index} value={item.BankNo}>{item.BankName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            
                            <div className="col-span-12">
                                <h6 className="text-xl font-medium">8. Remarks</h6>
                                <textarea onChange={inputData} name="Remarks" id="remarks" className="w-full p-2 border rounded-md text-xl" />
                            </div>
                            <div className="col-span-12 text-center">
                                <input type='submit' value="SUBMIT" className="text-xl bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" disabled={buttonClicked || (Number(totalPayable) === 0 && studentData.TransactionType === 'Tuition')} />
                            </div>
                        </div>
                    </form>
                </div>
                <div className='max-w text-center p-3'>
                    <Link to='../' className='pb-3 text-xl underline'>BACK</Link>
                </div>
            </div>
        </>
    )
}

export default Payments;
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import studentCreateQueue from '../../../services/Student/create/index'
import PermitModalNotification from './Modals/Permit';

function Permit() {

    const { data, authorizationHeader } = useAuth();
    const [queueno, setQueueNo] = useState(0)
    const [modalPaymentNotificationStatus, setModalPaymentNotificationStatus] = useState(false);

    const create = new studentCreateQueue();

    const handleRequestPermit = async () => {
        const respermit = await create.createPermitTransaction(authorizationHeader, {StudentID: data.user.Identification});
        if (respermit.res[0][0].QueueNo) {
            setQueueNo(respermit.res[0][0].QueueNo);
            setModalPaymentNotificationStatus(true);
        } else {

        }
    }

    return (
        <>
            { modalPaymentNotificationStatus && <PermitModalNotification modalStatus={modalPaymentNotificationStatus} setModalStatus={setModalPaymentNotificationStatus} data={queueno} /> }
            <div className="max-w rounded overflow-hidden shadow-lg m-5 bg-white">
                <h5 className='font-bold text-xl mb-2 text-center p-3'>PERMIT</h5>
                <div className="px-6 py-4 text-xl">
                    <div className="flex flex-wrap mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 font-bold mb-2 text-xl" htmlFor="StudentID">STUDENT ID</label>
                            <input defaultValue={data?.user?.Identification} className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="StudentID" name="StudentID" type="text" placeholder="2019-321145" disabled />
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 font-bold mb-2 text-xl" htmlFor="FullName">FULL NAME</label>
                            <input defaultValue={data?.user?.fullname} className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="FullName" type="text" placeholder="Juan Dela Cruz" disabled />
                        </div>
                        <div className="w-full text-center px-3 mb-6 md:mb-0">
                            <button onClick={handleRequestPermit} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' value='submit'>REQUEST PERMIT</button>
                        </div>
                    </div>
                </div>
                <div className='max-w text-center p-3'>
                    <Link to='../' className='pb-3 underline text-xl'>BACK</Link>
                </div>
            </div> 
        </>
    )
}

export default Permit
import httpService from "../../http.service";

class cashierCreateQueue {

    createQueueSelectStudent = async (authorizationHeader, data) => {
        const config = {
            headers: {
              authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return await  httpService.post('cashier/createQueueSelectStudent', data, config);
    }

    createPayments = async (authorizationHeader, data) => {
        const config = {
            headers: {
              authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return await  httpService.post('cashier/createPayments', data, config);
    }

    createRegistrarStudentPermit = async (authorizationHeader, data) => {
        const config = {
            headers: {
              authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return await  httpService.post('cashier/createStudentPermit', data, config);
    }


}

export default cashierCreateQueue;
import React, { Component } from 'react';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: props.onShow, 
    };
  }

  render() {
    const { size = 'medium', children, className } = this.props;
    const { isShown } = this.state;
    
    if (!isShown) return null; 
    
    const sizeClasses = {
      small: 'max-w-sm',
      medium: 'max-w-lg',
      large: 'max-w-2xl',
      half: 'w-1/2',  
      fullscreen: 'w-screen h-screen', 
      sixty: 'w-3/5',
      twoFour: 'w-2/4',
      xxl: 'max-w-4xl'
    };

    return (
      <div className={`fixed inset-0 flex items-center justify-center z-50 rounded ${className}`}>
        <div className="fixed inset-0 bg-black opacity-50" />
        
        <div className={`bg-white rounded-lg shadow-lg w-full mx-4 z-10 ${sizeClasses[size]}`}>
          {children}
        </div>
      </div>
    );
  }
}

Modal.Header = ({ title, children, className, style = {} }) => (
  <div className={`flex justify-between items-center p-4 border-b ${className}`} style={style}>
    <div className="flex-1">
      <h2 className="text-xl font-semibold">{title}</h2>
      {children && <div className="mt-2">{children}</div>} 
    </div>
    <button 
      className="text-gray-500 hover:text-gray-700 ml-4"
      disabled 
    >
      &times;
    </button>
  </div>
);

Modal.Body = ({ children, className, style = {} }) => (
  <div className={`p-4 ${className}`} style={style}>
    {children}
  </div>
);

export default Modal;

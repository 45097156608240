
class currencyPH {

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PHP' 
    });

    formatToCurrency(amount) {
        if (isNaN(amount)) {
            throw new Error('Input must be a number');
        }
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    }
}

export default currencyPH;
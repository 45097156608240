import React, { useEffect, useState } from 'react';
import cashierViewQueue from '../../services/Cashier/view';
import { useAuth } from '../../context/AuthContext';
import TextToSpeech from '../../utils/TextToSpeech';

const QueueDisplay = () => {
    const [selectedCustomer, setSelectedCustomer] = useState([]);

    const [queueList, setQueueList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 13;

    const recashier = new cashierViewQueue();
    const { authorizationHeader, data } = useAuth();

    const AssignRole = data?.user?.Role;
    const IdentificationUID = data?.user?.IdentificationUID;

    const getQueryCashierListFunc = () => {
        try {
            if (!AssignRole) return;
            recashier.getQueryCashierList(authorizationHeader, { AssignRole }).then((res) => {
                setQueueList(res.rows);
            }).catch((err) => {
                console.error(err);
            });
        } catch(err) {
            console.error(err);
        }
    };

    const getSelectedStudent = () => {
        try {
            if (!IdentificationUID) return;
            recashier.getSelectedStudent(authorizationHeader, { IdentificationUID }).then((res) => {
                setSelectedCustomer(res.rows);
            }).catch((err) => {
                console.error(err);
            })
        } catch(err) {
            console.error(err);
        };
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getQueryCashierListFunc();
            getSelectedStudent();
        }, 1000);
        
        return () => clearInterval(interval);
    });

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedList = queueList.slice(startIndex, endIndex);

    const handleNextPage = () => {
        if (endIndex < queueList.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="w-full mx-auto p-4 ">
            <div className="p-4 mb-6 rounded-lg text-white bg-indigo-500">
                <h2 className="text-2xl font-bold mb-5 text-center text-dark">Now Serving</h2>
                <div className="flex flex-row space-x-4">
                    {selectedCustomer?.slice(0, 3).map((customer, index) => (
                        <div key={index} className="flex flex-row space-x-4 w-full text-center">
                            <div className="p-6 bg-lime-700 rounded-lg text-white w-1/3">
                                <table className="min-w-full">
                                    <tbody>
                                        <tr>
                                            <td className="px-4 py-2 text-lg font-bold">Queue No:</td>
                                        </tr>
                                        <tr>
                                            <td className="text-6xl font-bold">{customer?.QueueNo}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="p-6 bg-lime-700 rounded-lg text-white w-1/3">
                                <table className="min-w-full">
                                    <tbody>
                                        <tr>
                                            <td className="px-4 py-2 text-lg font-bold">Name:</td>
                                        </tr>
                                        <tr>
                                            <td className="text-6xl font-bold">{customer?.FullName}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="p-6 bg-lime-700 rounded-lg text-white w-1/3">
                                <table className="min-w-full">
                                    <tbody>
                                        <tr>
                                            <td className="px-4 py-2 text-lg font-bold">Transaction:</td>
                                        </tr>
                                        <tr>
                                            <td className="text-6xl font-bold">{customer?.Transaction}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="overflow-x-auto text-xl">
                <table className="min-w-full table-auto border-collapse">
                    <thead className="text-start">
                        <tr className="bg-gray-200">
                            <th className="px-4 py-2 border">No.</th>
                            <th className="px-4 py-2 border">Date</th>
                            <th className="px-4 py-2 border">Queue No.</th>
                            <th className="px-4 py-2 border">Name</th>
                            <th className="px-4 py-2 border">Section</th>
                            <th className="px-4 py-2 border">Type of Transaction</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedList.map((customer, index) => (
                            <tr key={index} className="text-start">
                                <td className="px-4 py-2 border">{startIndex + index + 1}</td>
                                <td className="px-4 py-2 border">{new Date(customer?.date).toISOString().split('T')[0]}</td>
                                <td className="px-4 py-2 border">{customer?.queueno}</td>
                                <td className="px-4 py-2 border">{customer?.fullname}</td>
                                <td className="px-4 py-2 border">{customer?.section}</td>
                                <td className="px-4 py-2 border">{customer?.transactiontype}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-between mt-4">
                    <button
                        className="px-4 py-2 bg-indigo-500 text-white rounded-md disabled:opacity-50"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span>Page {currentPage}</span>
                    <button
                        className="px-4 py-2 bg-indigo-500 text-white rounded-md disabled:opacity-50"
                        onClick={handleNextPage}
                        disabled={endIndex >= queueList.length}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default QueueDisplay;

import httpService from "../../http.service";

class studentViewQueue {

    getStudentQueriesList = async (authorizationHeader, data) => {
        const config = {
            headers: {
              Authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return httpService.getSingle('student/getStudentQueriesList', data, config)
    }

    getStudentQueryCashierData = async (authorizationHeader, data) => {
        const config = {
            headers: {
              Authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return httpService.getSingle('cashier/getStudentQueryCashierData', data, config)
    }

}

export default studentViewQueue;
import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../context/AuthContext';
import studentViewQueue from '../../../services/Student/view';
import cashierDeleteQueue from '../../../services/Cashier/delete';

function Index() {
    
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    let isConfirmed;

    const [transactions, setTransactions] = useState([]);
    const { authorizationHeader, data } = useAuth();
    const delcashier = new cashierDeleteQueue();

    const viewStudent = new studentViewQueue();

    const totalPages = Math.ceil(transactions.length / rowsPerPage);
    
    const currentTransactions = transactions.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const listQueriesListFunc = async () => {
        try {
            const viewStudentData = await viewStudent.getStudentQueriesList(authorizationHeader, { StudentID:  data.user.Identification });
            setTransactions(viewStudentData.rows);
        } catch (err) {
            console.error(err);
        }
    }

    const handleDeleteTransaction = (e) => {
        switch(e.transactiontype) {
            case 'Tuition':
            case 'Other':
                isConfirmed = window.confirm("Are you sure you want to proceed?");

                if (isConfirmed) {
                    delcashier.deleteQueuePaymentTransactionStudent(authorizationHeader, { QueueStudentNo: e.queuestudentno}).then((res) => {
                        window.location.reload();
                    }).catch((err) => {
                        console.error(err);
                    })
                } 
                break;
            case 'Permit':
                isConfirmed = window.confirm("Are you sure you want to proceed?");

                if (isConfirmed) {
                    delcashier.deleteQueuePermitTransactionStudent(authorizationHeader, { QueueStudentNo: e.queuestudentno}).then((res) => {
                        console.log(res)
                        window.location.reload();
                    }).catch((err) => {
                        console.error(err);
                    })
                } 
                break;
            default:
                alert('Error!');
                break;

        }
    }

    useEffect(() => {
        listQueriesListFunc();
    })

    return (
        <>
            <div className="container mx-auto my-8 shadow-lg p-5">
                <table className="min-w-full table-auto font-bold">
                    <thead>
                        <tr className="bg-gray-200 text-gray-700 uppercase leading-normal">
                            <th className="py-3 px-6 text-left">No.</th>
                            <th className="py-3 px-6 text-left">Date</th>
                            <th className="py-3 px-6 text-left">Queue No.</th>
                            <th className="py-3 px-6 text-left">Name</th>
                            <th className="py-3 px-6 text-left">Type of Transaction</th>
                            <th className="py-3 px-6 text-left">Status</th>
                            <th className="py-3 px-6 text-left"></th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 font-light">
                        {currentTransactions.map((transaction, index) => (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-left">{transaction.queueno}</td>
                                <td className="py-3 px-6 text-left">{new Date(transaction.date).toLocaleString('en-US', { hour12: true })}</td>
                                <td className="py-3 px-6 text-left">{transaction.queueno}</td>
                                <td className="py-3 px-6 text-left">{transaction.fullname}</td>
                                <td className="py-3 px-6 text-left">{transaction.transactiontype}</td>
                                <td className="py-3 px-6 text-left">
                                    <span
                                        className={`py-1 px-3 rounded-full text-xs ${
                                            transaction.status === 'Completed'
                                                ? 'bg-green-200 text-green-800'
                                                : transaction.status === 'Pending'
                                                ? 'bg-yellow-200 text-yellow-800'
                                                : 'bg-red-200 text-red-800'
                                        }`}
                                    >
                                        {transaction.status}
                                    </span>
                                </td>
                                <td className="py-3 px-6 text-left">
                                    <div>
                                        <button onClick={() => handleDeleteTransaction(transaction)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2">
                                        DELETE
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Pagination Controls */}
                <div className="flex justify-between items-center mt-4">
                    <button
                        className={`bg-blue-500 text-white px-4 py-2 rounded ${
                            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span> Page {currentPage} of {totalPages} </span>
                    <button
                        className={`bg-blue-500 text-white px-4 py-2 rounded ${
                            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    );
}

export default Index;

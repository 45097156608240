
class dateTime {

    dateDefaultInput = (dateString) => {
        const date = new Date(dateString);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    militaryDateTimeToStandard = (dateFromDB) => {
        if (dateFromDB) {
            var date = new Date(dateFromDB);
            var year = date.getFullYear();
            var month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
            var day = String(date.getDate()).padStart(2, '0');
            var hours = date.getHours();
            var minutes = String(date.getMinutes()).padStart(2, '0');
    
            var period = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12; 
    
            // Construct datetime value string
            return `${year}-${month}-${day} ${hours}:${minutes} ${period}`;
        } else {
            return "";
        }
    }

    dateDefaultInput = (dateString) => {
        const date = new Date(dateString);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

}

export default dateTime;
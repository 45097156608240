import httpService from "../../http.service";

class cashierDeleteQueue {

    deleteQueuePaymentTransaction = (authorizationHeader, data) => {
        const config = {
            headers: {
                authorization: `Bearer ${authorizationHeader}`
            }
        }
        return httpService.delete('cashier/deleteQueuePaymentTransaction', data, config);
    }

    deleteQueuePermitTransaction = (authorizationHeader, data) => {
        console.log(data)
        const config = {
            headers: {
                authorization: `Bearer ${authorizationHeader}`
            }
        }
        return httpService.delete('cashier/deleteQueuePermitTransaction', data, config);
    }

    deleteQueuePaymentTransactionStudent = (authorizationHeader, data) => {
        const config = {
            headers: {
                authorization: `Bearer ${authorizationHeader}`
            }
        }
        return httpService.delete('cashier/deleteQueuePaymentTransactionStudent', data, config);
    }

    deleteQueuePermitTransactionStudent = (authorizationHeader, data) => {
        console.log(data)
        const config = {
            headers: {
                authorization: `Bearer ${authorizationHeader}`
            }
        }
        return httpService.delete('cashier/deleteQueuePermitTransactionStudent', data, config);
    }

}

export default cashierDeleteQueue;
class CookieManager {
  /**
   * Securely set a cookie
   * @param {string} name - The name of the cookie
   * @param {string} value - The value of the cookie
   * @param {number} days - The number of days before the cookie expires
   */
  setSecureCookie(name, value, hours) {
    let expires = '';
    if (hours) {
      const date = new Date();
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value || ''}${expires}; path=/; Secure; SameSite=Strict`;
  }

  /**
   * Get a cookie by name
   * @param {string} name 
   * @returns {string | null} 
   */
  getSecureCookie(name) {
    const nameEQ = `${name}=`;
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length);
    }
    return null;
  }

  /**
   * Delete a cookie by name
   * @param {string} name - The name of the cookie
   */
  removeSecureCookie(name) {
    document.cookie = `${name}=; Max-Age=-99999999; path=/; Secure; SameSite=Strict`;
  }
}

export default CookieManager;
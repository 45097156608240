import httpService from "../../http.service";

class cashierViewQueue {

    getStudentQueryListCashierToday = async (authorizationHeader, data) => {
        const config = {
            headers: {
              Authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return httpService.getSingle('cashier/getStudentQueryListToday', data, config)
    }

    getTotalPayable = async (authorizationHeader, data) => {
        const config = {
            headers: {
              Authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return httpService.getSingle('cashier/getTotalPayable', data, config)
    }

    getQueryCashierList = async (authorizationHeader, data) => {
        const config = {
            headers: {
              Authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return httpService.getSingle('cashier/getQueryCashierList', data, config)
    }

    getTotalPayableStudentCopy = async (authorizationHeader, data) => {
        const config = {
            headers: {
              Authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return httpService.getSingle('cashier/getTotalPayableStudentCopy', data, config)
    }

    getSelectedStudent = async (authorizationHeader, data) => {
        const config = {
            headers: {
              authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return httpService.getSingle('cashier/getSelectedStudent', data, config)
    }

    getTotalPayableCashierCopy = async (authorizationHeader, data) => {
        const config = {
            headers: {
              authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        };
        return httpService.getSingle('cashier/getTotalPayableCashierCopy', data, config)
    }

    getQueryStudentEnlistment = (authorizationHeader, data) => {
        const config = {
            headers: {
                authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        }
        return httpService.getSingle('cashier/getStudentEnlistment', data, config);
    }

    getEnrolledSubjectListPermit = (authorizationHeader, data) => {
        const config = {
            headers: {
                authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        }
        return httpService.getSingle('cashier/getEnrolledSubjectListPermit', data, config);
    }

    getStudentQueryPermitData = (authorizationHeader, data) => {
        const config = {
            headers: {
                authorization: `Bearer ${authorizationHeader}`
            },
            params: data
        }
        return httpService.getSingle('cashier/getStudentQueryPermitData', data, config);
    }

}

export default cashierViewQueue;
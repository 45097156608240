import jsPDF from "jspdf";
import dateTime from "../Date";

const ShowPermitGenerator = (data, subjects) => {

  const date = new dateTime();

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [80, 140], // 3.14961 inches width in mm, height same as previous
  });

  doc.setFont("helvetica", "bold");
  doc.setFontSize(5); // Smaller font size
  doc.text("Please check appropriate box", 3, 5);
  doc.setFont("helvetica", "normal");
  doc.text("PRELIM", 7, 10);
  doc.text("MIDTERM", 7, 14);
  doc.text("FINALS", 7, 18);

  doc.rect(3, 8, 3, 3); // Prelim
  doc.rect(3, 12, 3, 3); // Midterm
  doc.rect(3, 16, 3, 3); // Finals

  doc.setFillColor(0, 0, 0);
  if (data.ExamPeriod === 'PR') {
    doc.rect(3, 8, 3, 3, "F");
  } else if (data.ExamPeriod === 'MT') {
    doc.rect(3, 12, 3, 3, 'F');
  } else {
    doc.rect(3, 16, 3, 3, 'F');
  }

  const pageWidth = doc.internal.pageSize.getWidth();
  doc.setFont("helvetica", "bold");
  doc.text("ILOILO MERCHANT MARINE SCHOOL", pageWidth / 2, 22, { align: "center" });
  doc.text("EXAMINATION PERMIT", pageWidth / 2, 25, { align: "center" });

  doc.setFont("helvetica", "normal");
  doc.text(`${data.SemDisplay}, AY ${data.AY}`, pageWidth / 2, 28, { align: "center" });

  // Main text fields with equal centering
  doc.setFontSize(4);
  let sectionWidth = 30; // Width of each section for equal centering

  // Student Name and Permit ID centered
  let textWidth = doc.getTextWidth(data.StudentName);
  doc.text(data.StudentName, 10 + (sectionWidth - textWidth) / 2, 34);

  textWidth = doc.getTextWidth(data.PermitID);
  doc.text(data.PermitID, 43 + (sectionWidth - textWidth) / 2, 34);

  doc.line(10, 35, 40, 35);
  doc.line(43, 35, 70, 35);

  // Student ID and Date Issued centered
  textWidth = doc.getTextWidth(data.StudentID);
  doc.text(data.StudentID, 10 + (sectionWidth - textWidth) / 2, 42);

  textWidth = doc.getTextWidth(date.dateDefaultInput(data.IssuedOn));
  doc.text(date.dateDefaultInput(data.IssuedOn), 43 + (sectionWidth - textWidth) / 2, 42);

  doc.line(10, 43, 40, 43);
  doc.line(43, 43, 70, 43);

  // Registrar and Cashier centered
  textWidth = doc.getTextWidth(data.Registrar);
  doc.text(data.Registrar, 10 + (sectionWidth - textWidth) / 2, 50);

  textWidth = doc.getTextWidth(data.Cashier);
  doc.text(data.Cashier, 43 + (sectionWidth - textWidth) / 2, 50);

  doc.line(10, 51, 40, 51);
  doc.line(43, 51, 70, 51);

  // Centering the labels
  doc.setFont("helvetica", "normal");

  textWidth = doc.getTextWidth("Student Name");
  doc.text("Student Name", 10 + (sectionWidth - textWidth) / 2, 37);

  textWidth = doc.getTextWidth("Permit No");
  doc.text("Permit No", 43 + (sectionWidth - textWidth) / 2, 37);

  textWidth = doc.getTextWidth("Student No");
  doc.text("Student No", 10 + (sectionWidth - textWidth) / 2, 45);

  textWidth = doc.getTextWidth("Date Issued");
  doc.text("Date Issued", 43 + (sectionWidth - textWidth) / 2, 45);

  textWidth = doc.getTextWidth("Registrar");
  doc.text("Registrar", 10 + (sectionWidth - textWidth) / 2, 53);

  textWidth = doc.getTextWidth("Cashier");
  doc.text("Cashier", 43 + (sectionWidth - textWidth) / 2, 53);

  let yPosition = 58;
  const rowHeight = 4;

  doc.setFont("helvetica", "bold");
  doc.setLineWidth(0.01);
  doc.rect(10, yPosition, 60, rowHeight);
  doc.line(30, yPosition, 30, yPosition + rowHeight);
  doc.text("COURSE (SUBJECT)", 12, yPosition + 3);
  doc.text("INSTRUCTOR'S INITIAL", 40, yPosition + 3);

  doc.setFont("helvetica", "normal");
  subjects.map((subject) => {
    yPosition += rowHeight;
    doc.rect(10, yPosition, 60, rowHeight);
    doc.line(30, yPosition, 30, yPosition + rowHeight);
    doc.text(String(subject.CourseCode), 12, yPosition + 3);
  });

  yPosition += rowHeight;
  doc.setFontSize(4);
  doc.setFont("helvetica", "bold");
  doc.text("NOTE", 3, yPosition + 6);
  doc.setFont("helvetica", "normal");
  doc.text("1. NO PERMIT, NO EXAM.", 3, yPosition + 9);
  doc.text("2. THIS PERMIT IS PERSONAL AND NON-TRANSFERABLE.", 3, yPosition + 12);
  doc.text("3. IF THIS PERMIT IS DESTROYED, LOST OR MISPLACED, A DUPLICATE SHOULD BE REQUESTED.", 3, yPosition + 15);

  const pdfData = doc.output("blob");
  const pdfUrl = URL.createObjectURL(pdfData);
  window.open(pdfUrl, '_blank', 'noopener,noreferrer,width=800,height=600');
};

export default ShowPermitGenerator;

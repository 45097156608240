import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

function Index() {
    
    const navigate = useNavigate();
    const location = useLocation(); 

    const [selectedTransaction, setSelectedTransaction] = useState([]);

    
    const handleCashierButton = (e) => {
        e.preventDefault();
        switch(selectedTransaction.CashierTransaction) {
            case '1':
                navigate('cashier-student-transaction-payments');
                break;
            default:
                alert('Invalid Select!');
                break;
        }
    };

    const inputSelectedID = (e) => {
        setSelectedTransaction({
            ...selectedTransaction,
            [e.target.name]: e.target.value
        });
    };

    const shouldHideContent = location.pathname.includes('cashier-student-transaction-payments') || location.pathname.includes('cashier-student-transaction-statementofaccount');

    return (
        <>
            {
                !shouldHideContent ? 
                    <div className="w-full p-3 h-5/6">
                        <div className="flex justify-center items-center w-full p-5 h-full">
                            <div className="w-1/4 mx-auto mt-4 shadow-lg p-5">
                                <form noValidate onSubmit={handleCashierButton}>
                                    <div className="w-full mb-5 ">
                                        <label htmlFor='CashierTransaction' className='text-black mb-3 text-xl text-start'>Type of Transaction</label>
                                        <select 
                                            id="CashierTransaction" 
                                            name="CashierTransaction" 
                                            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-xl mt-3 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                            onChange={inputSelectedID}
                                            required
                                        >
                                            <option>-- Select Transaction --</option>
                                            <option value="1">Payments</option>
                                            {
                                                /*
                                                    <option value="1">Payments</option>
                                                        <option value="2">Statement of Account</option>
                                                */
                                            }
                                        </select>
                                    </div>
                                    <div className="w-full mb-5">
                                        <button type='submit' className="w-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"> CONTINUE</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                :
                <Outlet />
            }
        </>
    )
};

export default Index
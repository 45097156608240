import React, { useEffect, useState } from 'react'
import Modal from '../../../../components/Modal'
import Other from '../../../../services/Other';
import { useAuth } from '../../../../context/AuthContext';
import studentViewQueue from '../../../../services/Student/view';
import cashierViewQueue from '../../../../services/Cashier/view';
import cashierUpdateQueue from '../../../../services/Cashier/update';
import TextToSpeech from '../../../../utils/TextToSpeech';
import cashierCreateQueue from '../../../../services/Cashier/create';
import GenerateOfficialReceipt from '../../../../utils/PDF/GenerateOfficialReciept'
import cashierDeleteQueue from '../../../../services/Cashier/delete';

function AcceptPaymentCashier(props) {

    const { modalStatus, setModalStatus, data } = props;
    const [isSuccess, setIsSucccess] = useState();
    const [paymentData, setPaymentData] = useState([]);
    const [bankList, setBankList] = useState([]);
    const { authorizationHeader } = useAuth();
    const { data: userData } = useAuth();
    const other = new Other();
    const resstudent = new studentViewQueue();
    const rescashier = new cashierViewQueue();
    const upcashier = new cashierUpdateQueue();
    const crcashier = new cashierCreateQueue();
    const delcashier = new cashierDeleteQueue();

    const text = `Now serving Queue Number: ${data.queueno} - ${data.fullname}. Please proceed at the ${data.assignrole === 'STF_CAS' ? 'CASHIER' : 'REGISTRAR'} WINDOW!`;

    const inputData = (e) => {
        setPaymentData({
            ...paymentData,
            [e.target.name]: e.target.value
        });
    }
    
    const getBankListFunc = () => {
        other.getBankList(authorizationHeader).then((res) => {
            setBankList(res.rows);
        }).catch((err) => {
            console.error(err.message);
        });
    }

    const getStudentQueryCashierData = () => {
        resstudent.getStudentQueryCashierData(authorizationHeader, { QueueStudentNo: data.queuestudentno })
            .then((res) => {
                setPaymentData(res.rows[0]);
                return rescashier.getTotalPayable(authorizationHeader, { StudentID: data.studentid });
            })
            .then((res) => {
                setPaymentData((prevData) => ({
                    ...prevData,
                    TotalPayable: Number(res.rows[0].TotalPayable).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                }));
            })
            .catch((err) => {
                console.error(err.message);
            });
    };    

    const handleSubmitPayment = async (e) =>{
        e.preventDefault();
        if (paymentData.Amount === 0 && paymentData.Amount === undefined && paymentData.Amount === null && !paymentData.Amount) { 
            alert('Amount must Valid!') 
            return;     
        }

        if (paymentData.ORNo === undefined && paymentData.ORNo === null && !paymentData.ORNo  && String(paymentData.ORNo).length < 1) { 
            alert('OR must Valid!') 
            return; 
        } 

        if (data.transactiontype === 'Tuition') {
            
            crcashier.createPayments(authorizationHeader, paymentData).then((res) => {
                if (res.err) {
                    alert(res.err.sqlMessage);
                } else {
                    upcashier.updateQueueSelectStudentSuccessful(authorizationHeader, { QueueStudentNo: data.queuestudentno }).then((res) => {
                        console.log(res)
                        rescashier.getQueryStudentEnlistment(authorizationHeader, { registrationno: paymentData.RegistrationNo }).then((res) => {
                            const studentData = res.rows[0];
                            const oRData = { ...paymentData, Year: studentData.year, Course: studentData.code, Sem: studentData.sem === '1ST' ? '1st Sem' : studentData.sem === '2ND' ? '2nd Sem' : studentData.sem === '3RD' ? '3rd Sem' : 'Summer', }
                            GenerateOfficialReceipt(oRData, userData)
                            alert('Successfull Payment');
                            setIsSucccess(true);
                        }).catch((err) => {
                            alert(err)
                        });
                    }).catch((err) => {
                        alert(err)
                    });
                }
            }).catch((err) => {
                console.log(err);
            })
        } else {
            upcashier.updateQueueSelectStudentSuccessful(authorizationHeader, { QueueStudentNo: data.queuestudentno }).then((res) => {
                if (res.rows.changedRows > 0) {
                    rescashier.getQueryStudentEnlistment(authorizationHeader, { registrationno: paymentData.RegistrationNo }).then((res) => {
                        const studentData = res.rows[0];
                        const oRData = { ...paymentData, Year: studentData.year, Course: studentData.code, Sem: studentData.sem === '1ST' ? '1st Sem' : studentData.sem === '2ND' ? '2nd Sem' : studentData.sem === '3RD' ? '3rd Sem' : 'Summer', }
                        GenerateOfficialReceipt(oRData, userData)
                        alert('Successfull Payment');
                        setIsSucccess(true);
                    }).catch((err) => {
                        console.error(err);
                    });
                } else {
                    console.error(res);
                }
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    const handleCancelPayment = (e) => {
        e.preventDefault();
        upcashier.updateQueueSelectStudent(authorizationHeader, { QueueStudentNo: data.queuestudentno, UserUID: userData.user.IdentificationUID }).then((res) => {
            alert('Successfully cancelled Process!');
            setModalStatus(false)
        }).catch((err) => {
            console.error(err.message)
        })
    }

    const handleDeletePermit = (e) => {
        e.preventDefault();
        const isConfirmed = window.confirm("Are you sure you want to proceed?");

        if (isConfirmed) {
            delcashier.deleteQueuePermitTransaction(authorizationHeader, { QueueStudentNo: data.queuestudentno, UserUID: userData.user.IdentificationUID }).then((res) => {
                alert('Successfully deleted transaction!');
                window.location.reload();
            }).catch((err) => {
                console.error(err);
            })
        } 
    };

    useEffect(() => {
        getBankListFunc()
        getStudentQueryCashierData()
    }, [])

    return (
        <>
            <Modal onShow={modalStatus} onClose={setModalStatus} size={'fullscreen'} >
                <Modal.Header>
                    <div className='w-full'>
                        <h5 className='text-2xl font-bold'>Student Payment</h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex items-center justify-center items-center h-screen bg-slate-600'>
                        <div className='w-1/2 bg-white rounded-lg shadow-lg overflow-hidden p-4'>
                            <div className='w-full text-center'>
                                <p className='text-xl font-bold'>{data.transactiontype.toUpperCase()} PAYMENT</p>
                                <p className='text-lg'>QUEUE NO {data.queueno}</p>
                            </div>
                            <div className='m-4'>
                                <TextToSpeech text={text} />
                            </div>
                            <form noValidate={false}>
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="col-span-3 mt-2">
                                        <h6 className="font-semibold">Registered No.</h6>
                                        <input defaultValue={paymentData.RegistrationNo} className="form-control w-full border rounded px-2 py-1" type="text" disabled />
                                    </div>
                                    <div className="col-span-2 mt-2">
                                        <h6 className="font-semibold">Student ID</h6>
                                        <input defaultValue={paymentData.StudentID} className="form-control w-full border rounded px-2 py-1" type="text" disabled />
                                    </div>
                                    <div className="col-span-5 mt-2">
                                        <h6 className="font-semibold">Student Name</h6>
                                        <input defaultValue={paymentData.FullName} className="form-control w-full border rounded px-2 py-1" type="text" disabled />
                                    </div>
                                    {
                                        data.transactiontype === 'Tuition' ? (
                                            <div className="col-span-2 mt-2">
                                                <h6 className="font-semibold">Total Payable</h6>
                                                <input defaultValue={paymentData.TotalPayable && paymentData.TotalPayable} className="form-control w-full border rounded px-2 py-1" type="text" disabled />
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="col-span-2 mt-3"></div>
                                        )
                                    }
                                    <div className="col-span-12 mt-4 mb-3">
                                        <hr />
                                    </div>
                                    <div className="col-span-3 mt-2">
                                        <h6 className="font-semibold">Payment Mode</h6>
                                        <select value={paymentData.PaymentMode && paymentData.PaymentMode} onChange={inputData} name="PaymentMode" className="form-control w-full border rounded px-2 py-1" required>
                                            <option value="">Please Select Item...</option>
                                            <option value="Cash">Cash</option>
                                            <option value="Cheque">Cheque</option>
                                        </select>
                                    </div>
                                    <div className="col-span-3 mt-2">
                                        <h6 className="font-semibold">OR No.</h6>
                                        <input onChange={inputData} name="ORNo" className="form-control w-full border rounded px-2 py-1" type="text" required />
                                    </div>
                                    <div className="col-span-3 mt-2">
                                        <h6 className="font-semibold">Amount</h6>
                                        <input type="number" onChange={inputData} defaultValue={paymentData.Amount && paymentData.Amount} name="Amount" className="form-control w-full border rounded px-2 py-1" required />
                                    </div>
                                    <div className="col-span-3 mt-2">
                                        <h6 className="font-semibold">Date Payment</h6>
                                        <input onChange={inputData} name="PaymentDate" defaultValue={paymentData.DatePayment && new Date(paymentData.DatePayment).toISOString().slice(0, 16)} className="form-control w-full border rounded px-2 py-1" type="datetime-local" required />
                                    </div>
                                    <div className="col-span-3 mt-2">
                                        <h6 className="font-semibold">Cheque Date</h6>
                                        <input onChange={inputData} name="ChequeDate" defaultValue={paymentData.ChequeDate && new Date(paymentData.ChequeDate).toISOString().slice(0, 16)} className="form-control w-full border rounded px-2 py-1" type="datetime-local" />
                                    </div>
                                    <div className="col-span-3 mt-2">
                                        <h6 className="font-semibold">Cheque No</h6>
                                        <input onChange={inputData} name="ChequeNo"  defaultValue={paymentData.ChequeNo && paymentData.ChequeNo} className="form-control w-full border rounded px-2 py-1" type="text" />
                                    </div>
                                    <div className="col-span-6 mt-2">
                                        <h6 className="font-semibold">Bank</h6>
                                        <select onChange={inputData} value={paymentData.Bank} className="form-control w-full border rounded px-2 py-1" name="BankNo" >
                                            <option></option>
                                            {bankList &&
                                                bankList.map((item, index) => (
                                                <option key={index} value={item.BankNo}>
                                                    {item.BankName}
                                                </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="col-span-12 mt-2">
                                        <h6 className="font-semibold">Remarks</h6>
                                        <textarea onChange={inputData} defaultValue={paymentData.Remarks} name="Remarks" id="remarks" className="form-control w-full border rounded px-2 py-1" />
                                    </div>
                                </div>
                                <div className="m-3 flex justify-center">
                                    {
                                        isSuccess ?
                                            <button onClick={() => window.location.reload()} style={{ width: '130px' }} type="submit" className="btn btn-primary me-2 bg-red-500 text-white rounded py-2 px-4" >CLOSE </button>
                                        :
                                            <>
                                                <button onClick={handleDeletePermit}  style={{ width: '130px' }} type="submit" className="px-6 py-2 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition ease-in duration-150 m-2">DELETE</button>
                                                <button onClick={handleSubmitPayment} style={{ width: '130px' }} type="submit" className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition ease-in duration-150 m-2" >CONFIRMED</button>
                                                <button onClick={handleCancelPayment} style={{ width: '130px' }} type="submit" className="px-6 py-2 bg-yellow-500 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition ease-in duration-150 m-2" >CANCEL </button>
                                            </>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AcceptPaymentCashier
import React, { useState } from 'react';

function Cashier() {
    const [searchTerm, setSearchTerm] = useState('');

    const data = [
        { id: 1, transactionType: 'Registration', name: 'John Doe', year: '2024', section: 'A' },
        { id: 2, transactionType: 'Enrollment', name: 'Jane Smith', year: '2023', section: 'B' },
        { id: 3, transactionType: 'Graduation', name: 'Mark Johnson', year: '2024', section: 'C' },
    ];

    // Function to filter the data based on the search term
    const filteredData = data.filter((user) => 
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.transactionType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.year.includes(searchTerm) ||
        user.section.toLowerCase().includes(searchTerm)
    );

    return (
        <>
        <div className="mx-auto px-4 flex flex-col items-center justify-center rounded-lg shadow-lg p-3">
            <div className="w-9/12 rounded-lg shadow-lg overflow-hidden">
                <div className="overflow-x-auto text-end">
                    <div className="p-4 bg-gray-200">
                        <div className='w-full text-center'>
                            <h4>CASHIER TRANSACTION</h4>
                        </div>
                        <input 
                            type="text" 
                            placeholder="Search..." 
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 w-1/6"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    <table className="min-w-full bg-white border border-gray-200 rounded-lg text-start">
                        <thead>
                            <tr>
                            <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-600 uppercase">Query No.</th>
                            <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-600 uppercase">Type of Transaction</th>
                            <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-600 uppercase">Name</th>
                            <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-600 uppercase">Year</th>
                            <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-600 uppercase">Section</th>
                            <th className="px-6 py-3 border-b text-center text-sm font-medium text-gray-600 uppercase"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((user) => (
                            <tr key={user.id} className="hover:bg-gray-100">
                                <td className="px-6 py-4 border-b text-sm text-gray-700">{user.id}</td>
                                <td className="px-6 py-4 border-b text-sm text-gray-700">{user.transactionType}</td>
                                <td className="px-6 py-4 border-b text-sm text-gray-700">{user.name}</td>
                                <td className="px-6 py-4 border-b text-sm text-gray-700">{user.year}</td>
                                <td className="px-6 py-4 border-b text-sm text-gray-700">{user.section}</td>
                                <td className="px-6 py-4 border-b text-center">
                                    <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 me-2">Appoint</button>
                                    <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 me-2">Failed</button>
                                    <button className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">Pending</button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    );
}

export default Cashier;

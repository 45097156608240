import htttpService from "../http.service"

class Authentication {

    userAuthentication = async (credentials, config) => {
        return await htttpService.post('auth/auth0', credentials, config);
    }
    
}

export default new Authentication();
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom';
import { StrictMode } from 'react';

import DashboardLayout from './layout/Main';

import Dashboard from './pages/Dashboard';
import CashierTransactionStudent from './layout/Cashier';
import RegistrarTransactionStudent from './layout/Registar';
import Cashier from './pages/Cashier';
import PaymentToCashier from './layout/Cashier/Components/Payments'
import PermitTransactionStudent from './layout/Registar/Components/Permit'
import ListOfStudentInquire from './layout/Modal/Student';
import CashierQuery from './layout/CashierQuery';
import RegistrarQuery from './layout/RegistrarQuery';
import QueueDisplay from './layout/Viewer';

function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
    <>
      <Route path='/' element={<DashboardLayout />}>
        <Route path='' element={<Dashboard />} />
        <Route path='cashier-student-transaction' element={<CashierTransactionStudent />}>
          { /* <Route path='cashier-student-transaction-statementofaccount' element={<CashierTransactionStudent />} /> */ }
          <Route path='cashier-student-transaction-payments' element={<PaymentToCashier />} />
        </Route>
        <Route path='registrar-query' element={<RegistrarQuery />} />
        <Route path='cashier-query' element={<CashierQuery />} />
        <Route path='registrar-student-transaction' element={<RegistrarTransactionStudent />}>
          <Route path='registrar-student-transaction-exampermit' element={<PermitTransactionStudent />} />
        </Route>
        <Route path='cashier' element={<Cashier />} >
          
        </Route>
        <Route path='student-queries' element={<ListOfStudentInquire />} />
        
      </Route>
      <Route path='view' element={<QueueDisplay />} />
    </>
  ));

  return (
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
}

export default App;
import React, { useState, useEffect } from "react";

const TextToSpeech = ({ text }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);

    setUtterance(u);

    return () => {
        synth.cancel();
        };
    }, [text]);
    const handlePlay = () => {
        const synth = window.speechSynthesis;

        if (isPaused) {
            synth.resume();
        } else {
            utterance.pitch = 5;
            utterance.rate = 1;
            utterance.volume = 50;
            synth.speak(utterance);
        }

        synth.speak(utterance);

        setIsPaused(false);
    };

    const handlePause = () => {
        const synth = window.speechSynthesis;

        synth.pause();

        setIsPaused(true);
    };

    const handleStop = () => {
        const synth = window.speechSynthesis;

        synth.cancel();

        setIsPaused(false);
    };

  return (
        <>
            <div className="flex justify-center w-full text-center">
                <div className="flex space-x-4">
                    <button onClick={handlePause} className="m-3 px-6 py-2 bg-yellow-500 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition ease-in duration-150" > Pause </button>
                    <button onClick={handlePlay} className="m-3 px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition ease-in duration-150" > {isPaused ? "Resume" : "Play"} </button>
                    <button onClick={handleStop} className="m-3 px-6 py-2 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition ease-in duration-150" > Stop </button>
                </div>
            </div>
        </>
    );
};

export default TextToSpeech;
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import NumberToWord from '../Currency/NumberToWords/NumberToWord';
import dateTime from '../Date';
import currencyPH from '../Currency';

const GenerateOfficialReciept = (PaymentData, data) => {

    const date = new dateTime();
    
    const currency = new currencyPH();

    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [80, 150],  
    });

    doc.setFontSize(5);
    doc.setFont("helvetica", "normal");

    // Header
    doc.text('Brgy. Cabugao Sur, Pavia, Iloilo 5001', 10, 8);
    doc.text('Tel. No. (033) 327-9973', 10, 10);
    doc.text('VAT Reg. TIN: 446-982-329-0000', 10, 12);

    doc.setFontSize(6);
    doc.text('(Educational)', 40, 17, { align: 'center' });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text('ILOILO MERCHANT MARINE SCHOOL', 40, 20, { align: 'center' });
    doc.setFont("helvetica", "bold");
    doc.text('OFFICIAL RECEIPT', 40, 23, { align: 'center' });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    doc.text('VAT-EXEMPT', 40, 25, { align: 'center' });

    // OR Number and Date
    doc.text('OR No.', 55, 32);
    doc.setTextColor(255, 0, 0);
    doc.text(PaymentData.ORNo || '', 55, 35);
    doc.setTextColor(0, 0, 0);

    doc.text('IMMS-ACCTG-002-F01', 10, 32);
    doc.text('Date and Time:', 10, 35);
    doc.text(date.militaryDateTimeToStandard(PaymentData.PaymentDate), 10, 38);

    // Student and Course Details
    doc.text('Student ID', 10, 43);
    doc.text('Student Name', 10, 46);
    doc.text(':', 27, 43);
    doc.text(':', 27, 46);
    doc.text(PaymentData.StudentID || '', 29, 43);
    doc.text(PaymentData.FullName || PaymentData.Fullname || PaymentData.fullname || '', 29, 46);

    // Payment details
    doc.autoTable({
        startY: 50,
        head: [['Payment Details', 'Amount']],
        body: [
            ['Tuition & Misc', `Php ${currency.formatToCurrency(Number(PaymentData.Amount))}`],
            [
                { content: 'Total', styles: { halign: 'center', fontStyle: 'bold' } },
                { content: `Php ${currency.formatToCurrency(Number(PaymentData.Amount))}`, styles: { halign: 'center' } },
            ],
            [{ content: 'Amount in Words', colSpan: 2, styles: { halign: 'left', fontStyle: 'bold', fontSize: 7.5 } }],
            [{ content: NumberToWord(Number(PaymentData.Amount)), colSpan: 2, styles: { halign: 'left' } }],
        ],
        theme: 'plain',
        styles: {
            fontSize: 7.5,
            halign: 'center',
            cellPadding: 1,
            lineWidth: 0.1,
        },
        columnStyles: {
            0: { cellWidth: 35 },
            1: { cellWidth: 25 },
        },
        margin: { left: 10, right: 10 },
    });

    // Payment Mode and Bank Details
    doc.autoTable({
        startY: doc.previousAutoTable.finalY + 3,
        head: [['Payment Mode', 'Drawee Bank']],
        body: [[PaymentData.PaymentMode || '', PaymentData.BankName || '']],
        theme: 'plain',
        styles: {
            fontSize: 7.5,
            halign: 'center',
            cellPadding: 1,
            lineWidth: 0.1,
        },
        columnStyles: {
            0: { cellWidth: 35 },
            1: { cellWidth: 25 },
        },
        margin: { left: 10, right: 10 },
    });

    doc.autoTable({
        startY: doc.previousAutoTable.finalY + 1,
        head: [['Number', 'Date']],
        body: [[PaymentData.ChequeNo || '', date.militaryDateTimeToStandard(PaymentData.ChequeDate) || '']],
        theme: 'plain',
        styles: {
            fontSize: 7.5,
            halign: 'center',
            cellPadding: 1,
            lineWidth: 0.1,
        },
        columnStyles: {
            0: { cellWidth: 35 },
            1: { cellWidth: 25 },
        },
        margin: { left: 10, right: 10 },
    });
    doc.text('Remaining Balance :', 10, 105);
    doc.text(` ${currency.formatToCurrency(Number(Number(String(PaymentData.TotalPayable).replace(/,/g, '')) - Number(PaymentData.Amount)))}`, 35, 105);
    // Footer
    doc.text('Received the amount stated above.', 10, 108);
    // Signature area
    const x = 10;
    const y = 115;
    const width = 60;
    const text = data.user.fullname.toUpperCase();
    const textWidth = doc.getTextWidth(text);
    const textX = x + (width - textWidth) / 2;
    doc.text(text, textX, y);
    const underlineY = y + 1;
    doc.setLineWidth(0.2);
    doc.line(textX, underlineY, textX + textWidth, underlineY);

    doc.text('Cashier', textX + (textWidth - doc.getTextWidth('Cashier')) / 2, underlineY + 3);

    // Disclaimer
    doc.setFontSize(5);
    doc.text('"THIS DOCUMENT IS NOT VALID FOR CLAIMING INPUT TAXES."', 40, 125, { align: 'center' });
    doc.text('THIS OFFICIAL RECEIPT SHALL BE VALID FOR FIVE (5) YEARS FROM THE DATE ATP.', 40, 128, { align: 'center' });

    // Enable auto print
    doc.autoPrint();

    // Open in a new tab
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    document.body.appendChild(iframe);
};

export default GenerateOfficialReciept;

import httpService from "../http.service";

class Other {
    
    getBankList = async (authorizationHeader) => {
        const config = {
            headers: {
              Authorization: authorizationHeader
            },
        };
        return await httpService.get('other/getBankList', config);
    }

}

export default Other;
import React, { useEffect, useState } from 'react'
import Modal from '../../../../components/Modal';
import TextToSpeech from '../../../../utils/TextToSpeech';
import cashierViewQueue from '../../../../services/Cashier/view';
import { useAuth } from '../../../../context/AuthContext';
import cashierCreateQueue from '../../../../services/Cashier/create';
import dateTime from '../../../../utils/Date';
import ShowPermitGenerator from '../../../../utils/PDF/StudentPermitGenerator';
import cashierUpdateQueue from '../../../../services/Cashier/update';
import cashierDeleteQueue from '../../../../services/Cashier/delete';

function AcceptPermitRegistrar(props) {

    const { modalStatus, setModalStatus, data } = props;
    const [permitData, setPermitData] = useState([]);
    const rescashier = new cashierViewQueue();
    const crecashier = new cashierCreateQueue();
    const upcashier = new cashierUpdateQueue();
    const delcashier = new cashierDeleteQueue();


    const [isSuccess, setIsSuccess] = useState(false);
    const date = new dateTime();

    const { data: userData, authorizationHeader } = useAuth();

    const text = `Now serving Queue Number: ${data.queueno} - ${data.fullname}. Please proceed at the ${data.assignrole === 'STF_CAS' ? 'CASHIER' : 'REGISTRAR'} WINDOW!`;

    const fetchPermitDataFunc = () => {
        rescashier.getStudentQueryPermitData(authorizationHeader, { QueueStudentNo: data?.queuestudentno }).then((res) => {
            setPermitData(res.rows[0]);
            console.log(res.rows[0])
        }).catch((err) =>{
            console.log(err);
        })
    }

    const handleCancelPermit = (e) => {
        e.preventDefault();
        upcashier.updateQueueSelectStudent(authorizationHeader, { QueueStudentNo: data.queuestudentno, UserUID: userData.user.IdentificationUID }).then((res) => {
            alert('Successfully cancelled Process!');
            setModalStatus(false)
        }).catch((err) => {
            console.error(err.message)
        })
    }

    const handleDeletePermit = (e) => {
        e.preventDefault();
        const isConfirmed = window.confirm("Are you sure you want to proceed?");

        if (isConfirmed) {
            delcashier.deleteQueuePermitTransaction(authorizationHeader, { QueueStudentNo: data.queuestudentno, UserUID: userData.user.IdentificationUID }).then((res) => {
                alert('Successfully deleted transaction!');
                window.location.reload();
            }).catch((err) => {
                console.error(err);
            })
        } 
    };

    const handleProceedCreate = (e) => {
        e.preventDefault();
        crecashier.createRegistrarStudentPermit(authorizationHeader, permitData).then((res) => {
            const studentData = {
                ...permitData,
                Registrar: res.res[0][0].RegistrarName,
                Cashier: res.res[0][0].CashierName,
                IssuedOn: date.dateDefaultInput(res.res[0][0].IssuedOn),
                StudentID: res.res[0][0].StudentID,
                PermitID: res.res[0][0].PermitID,
                StudentName: res.res[0][0].StudentName,
                SemDisplay: permitData.Sem === '1ST' ? 'First Semester' : permitData.Sem === '2ND' ? 'Second Semester' : permitData.Sem === '3RD' ? 'Third Semester' : permitData.Sem === 'SUM' ? 'Summer' : null
            }
            if (res.res[0][0].ErrorCode === 1062) {
                alert('Already Created! Please Check on the School Management System');
            } else {
                rescashier.getEnrolledSubjectListPermit(authorizationHeader, permitData).then((res) => {
                    const subject = res.rows;
                    upcashier.updateQueueSelectStudentSuccessful(authorizationHeader, { QueueStudentNo: data?.queuestudentno }).then((res) => {
                        if (res.rows.affectedRows > 0) {
                            ShowPermitGenerator(studentData, subject);
                            alert('Successfully Generated!');
                            setIsSuccess(true);
                        }
                    }).catch((err) => {
                        alert(res.err.sqlMessage)
                    })
                }).catch((err) => {
                    console.error(err)
                })
            }
        }).catch((err) => {
            console.error(err);
        })
    }

    useEffect(() => {
        fetchPermitDataFunc();
    }, [])

    return (
        <>
            <Modal onShow={modalStatus} onClose={setModalStatus} size={'fullscreen'} >
                <Modal.Header>
                    <div className='w-full'>
                        <h5 className='text-2xl font-bold'>Student Permit</h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex items-center justify-center items-center h-screen bg-slate-600'>
                        <div className='w-1/2 bg-white rounded-lg shadow-lg overflow-hidden p-4'>
                            <div className='w-full text-center'>
                                <p className='text-xl font-bold'>{data.transactiontype.toUpperCase()} PAYMENT</p>
                                <p className='text-lg'>QUEUE NO {data.queueno}</p>
                            </div>
                            <div className='m-4'>
                                <TextToSpeech text={text} />
                            </div>
                            <form noValidate={false}>
                                { /* Here */ }
                                <div className="m-3 flex justify-center w-full">
                                    <div className="grid grid-cols-1 gap-4">
                                        <div className="col-span-3 mt-2">
                                            <h6 className="font-semibold">Registered No.</h6>
                                            <input value={permitData.RegistrationNo && permitData.RegistrationNo} className="form-control w-full border rounded px-2 py-1" type="text" disabled />
                                        </div>
                                        <div className="col-span-2 mt-2">
                                            <h6 className="font-semibold">Student ID</h6>
                                            <input value={permitData.StudentID && permitData.StudentID} className="form-control w-full border rounded px-2 py-1" type="text" disabled />
                                        </div>
                                        <div className="col-span-5 mt-2">
                                            <h6 className="font-semibold">Student Name</h6>
                                            <input value={permitData.FullName && permitData.FullName} className="form-control w-full border rounded px-2 py-1" type="text" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full text-center">
                                    {
                                        isSuccess ? 
                                        <>
                                            <button onClick={() => window.location.reload()} className="px-6 py-2 bg-yellow-500 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition ease-in duration-150 m-2">CLOSE</button>
                                        </>
                                        :
                                        <>
                                            <button onClick={handleDeletePermit} className="px-6 py-2 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition ease-in duration-150 m-2">DELETE</button>
                                            <button onClick={handleProceedCreate} className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition ease-in duration-150 m-2">PROCEED</button>
                                            <button onClick={handleCancelPermit} className="px-6 py-2 bg-yellow-500 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition ease-in duration-150 m-2">CANCEL</button>
                                        </>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AcceptPermitRegistrar